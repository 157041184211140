<template>
  <footer class="footer">
    <div class="footer-main">
      <SvgElement
        name="LogoUpCities"
        class="footer-main__title"
      />
      <a
        href="https://api.whatsapp.com/send/?phone=5527995702015&text=UpCities&type=phone_number&app_absent=0"
        target="_blank"
        rel="noopener noreferrer"
        class="contact"
      >
        <p class="contact__text">
          Dúvidas? Estamos no Whatsapp:
        </p>
        <SvgElement
          name="Whatsapp"
          class="contact__icon"
        />
      </a>
      <ul class="footer-links">
        <li>
          <button
            class="footer-links__item"
            @click="goTo('services')"
          >
            Serviços
            <SvgElement
              name="ArrowRight"
              class="footer-links__item__icon"
            />
          </button>
        </li>
        <li>
          <button
            class="footer-links__item"
            @click="goTo('benefits')"
          >
            Benefícios
            <SvgElement
              name="ArrowRight"
              class="footer-links__item__icon"
            />
          </button>
        </li>
        <li>
          <button
            class="footer-links__item"
            @click="goTo('partners')"
          >
            Clientes
            <SvgElement
              name="ArrowRight"
              class="footer-links__item__icon"
            />
          </button>
        </li>
        <!-- <li>
          <a
            href="https://uppersoft.com.br/?s=upcities"
            target="_blank"
            rel="noopener noreferrer"
            class="footer-links__item"
          >
            Blog
            <SvgElement
              name="ArrowRight"
              class="footer-links__item__icon"
            />
          </a>
        </li> -->
      </ul>
    </div>
    <!-- <a
      href="https://uppersoft.com.br/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <SvgElement
        name="UmProdutoUppersoft"
        class="footer-bottom"
      />
    </a> -->
  </footer>
</template>

<script>
export default {
  name: 'Footer',

  methods: {
    goTo(nav) {
      const options = {
        behavior: 'smooth',
        block: nav === 'services' ? 'start' : 'center'
      };

      document.querySelector(`#${nav}`).scrollIntoView(options);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: $gray-text;
  color: $white;
  padding: 2.5rem 4% 3.5rem;

  @include screen(tablet-big-up) {
    padding: 2.5rem 8% 1rem;
  }

  @include screen(desktop-big-up) {
    padding: 2.5rem calc(50vw - 35rem) 1rem;
  }

  &-main {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include screen(tablet-big-up) {
      display: grid;
      grid-template-columns: auto 1fr auto;
      column-gap: 2.5rem;
      align-items: stretch;
      padding-bottom: 2rem;
      //border-bottom: 1px solid $gray-200;
      margin-bottom: 1rem;
    }

    &__title {
      order: 1;
      display: none;

      @include screen(tablet-big-up) {
        display: inherit;
        height: 100%;
      }
    }
  }

  &-bottom {
    @include screen(tablet-big-up) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.contact {
  margin-bottom: 2.5rem;
  order: 2;
  border-bottom: 1px solid $transparent;

  @include screen(tablet-big-up) {
    order: 3;
    display: flex;
    align-items: center;
    margin: 0;
    align-self: flex-end;

    &:hover {
      border-color: $white;
    }
  }

  &__text {
    margin-bottom: 1rem;
    font-size: $text-base;
    font-weight: $font-bold;

    @include screen(tablet-big-up) {
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
  }

  &__icon {
    display: inline;
    color: $white;
  }
}

.footer-links {
  border-top: 1px solid $gray-200;
  width: 100%;
  margin-bottom: 2rem;
  order: 3;

  @include screen(tablet-big-up) {
    order: 2;
    margin: 0;
    border: none;
    width: fit-content;
    display: flex;
    align-items: center;
  }

  &__item {
    border-bottom: 1px solid $gray-200;
    padding: 1rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $text-sm;
    outline: none;

    @include screen(tablet-big-up) {
      border-color: transparent;
      width: fit-content;
      padding: 0;
      height: 100%;
      font-size: $text-base;
      margin-right: 2.5rem;

      &:hover {
        border-color: $white;
      }
    }

    &__icon {
      color: $gray-200;

      @include screen(tablet-big-up) {
        display: none;
      }
    }
  }
}
</style>